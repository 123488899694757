import React, { useState, useEffect } from 'react';
import { Button } from "devextreme-react";
import {Popup, Position} from "devextreme-react/popup";
import List from 'devextreme-react/list';


function PublicFaqs(props) {

    const [faqData, setFaqData] = useState({});
    const [isInfoPopupVisible, setIfInfoPopupVisible] = useState(false);


    useEffect(() => {

        let arrObject = []
        for (let i = 0; i < props.faqData.length; i++) {
            let entryObject = {
                "key": props.faqData[i].FaqTopic,
                "items": [props.faqData[i].FaqBody]
            }
            // console.log(entryObject)
            arrObject[i] = entryObject
        }

        // console.log("done arr loading")
        // console.log(arrObject)
        console.log(arrObject)
        console.log(faqData)

        setFaqData(arrObject)

    }, [props.faqData])

    const showPopup = () =>{
        console.log(isInfoPopupVisible)
        setIfInfoPopupVisible(true)
    }

    const GroupTemplate = (item) => {
        //console.log(item)
        return `${item.key}`
    }



    const renderDivList = (a) =>{
        return <div style={{whiteSpace:"normal", height:"auto", pointerEvents:"none"}}>{a.text}</div>
//
    }
    const setVisibilityPopup = (a) =>{
        let lengthOfCSSProperties = Object.keys(a.pointers[0].target.style).length
        let classes = a.pointers[0].target.classList
        console.log(a.pointers[0].target.style)
        console.log(a.pointers[0].target.classList)
        console.log(a.pointers[0].target)
        console.log(lengthOfCSSProperties)


        if(lengthOfCSSProperties > 0){
            let shouldWeHide = false

            classes.forEach( obj =>{
                if(obj === "dx-popup-content"){
                    shouldWeHide = true
                }
            })

            setIfInfoPopupVisible(shouldWeHide)
        }

        classes.forEach(obj =>{
            if(obj === "dx-icon-close" || obj === "dx-button-content"){
                setIfInfoPopupVisible(false);
            }
        })

        return true
    }


    return(
        <div>
        <div style={{height:"60px", position:"fixed", right:props.divLocation, top:"2px"}}>
            <Button
                text={"?"}
                onClick={showPopup}
            />
        </div>


            <Popup
                visible={isInfoPopupVisible}
                dragEnabled={false}
                closeOnOutsideClick={setVisibilityPopup}
                showCloseButton={setVisibilityPopup}
                showTitle={true}
                title="FAQs"
                container=".dx-viewport"
                width={"90vw"}
                height={"95%"}
            >
                <Position
                    my="center"
                    at="center"
                    of={"window"}
                />
                    <List
                        dataSource={faqData}
                        height="90%"
                        grouped={true}
                        collapsibleGroups={false}
                        groupRender={GroupTemplate}
                        itemRender={renderDivList}
                        scrollingEnabled={true}
                        scrollByThumb={true}
                        useNativeScrolling={true}

                    />
            </Popup>
        </div>

    )
}


export default PublicFaqs