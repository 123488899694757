import React, { useState, useEffect } from 'react';
import { ProgressBar } from 'devextreme-react/progress-bar';
import 'devextreme/dist/css/dx.light.css';


function OpeningInformation(props){

    const[openingInfo,      setOpeningInfo] = useState([""]);
    const[durationOpening,  setDurationOpening] = useState([""]);
    const[closingTime,      setClosingTime] = useState([""]);
    const[previousOpeningTime, setPreviousOpeningTime] = useState("");
    const[shouldPreviousOpeningInfoBeHidden, setShouldPreviousOpeningInfoBeHidden] = useState(false);
    const[tempProgressBarStart, setTempProgressBarStart] = useState(0);
    const[tempProgressBarEnd, setTempProgressBarEnd] = useState(0);
    const[tempNextOpeningTime, setTempNextOpeningTime] = useState(0);
    const[tempNextClosingTime, setTempClosingTime] = useState(0);

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]

    const days = [
        'Sun',
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat'
    ]

    useEffect( () =>{
        console.log(props.stats)
    },[])


    useEffect( () =>{
        if(props.stats === undefined){
            return
        }


        switch(props.stats.BridgeStatus) {
            case -2:
                try{

                    // let formattedDate = `${props.stats.dtCloseStarted.date.replace(/ /g, 'T')}Z`
                    // let movementStarted = (new Date(`${formattedDate}`))
                    //
                    // // let movementStarted = (new Date(`${props.stats.dtCloseStarted.date}Z`))
                    //
                    // let hours = checkDateVars(movementStarted.getHours());
                    // let minutes = checkDateVars(movementStarted.getMinutes())
                    //
                    // let bridgeWillFinishMovementAt  = addTimeToDate(movementStarted,10)
                    // let endHours = checkDateVars(bridgeWillFinishMovementAt.getHours())
                    // let endMinutes = checkDateVars(bridgeWillFinishMovementAt.getMinutes())
                    //
                    // let movementStartedString = `${hours}:${minutes}`
                    // let movementWillFinish = `${endHours}:${endMinutes}`
                    // setOpeningInfo([`Movement started:`,movementStartedString])
                    // setClosingTime([`Movement will complete at approx:`, movementWillFinish])
                    return // we no longer have a 'closing' state
                }
            catch(e) {
                console.log("no movement started dt")
                setOpeningInfo([`Movement started:`, 'N/A'])
                setClosingTime([`Movement will approx at:`, 'N/A'])

            }
            break

            case -1:
                try {

                    let formattedDate = `${props.stats.dtMovementStarted.date.replace(/ /g, 'T')}Z`
                    let movementStarted = (new Date(`${formattedDate}`))

                    let datePlusTenMiutes =  new Date(movementStarted.getTime() + 750000)

                    let adjustHours = checkDateVars(datePlusTenMiutes.getHours())
                    let adjustedMinutes = checkDateVars(datePlusTenMiutes.getMinutes())

                    let hours = checkDateVars(movementStarted.getHours());
                    let minutes = checkDateVars(movementStarted.getMinutes())

                    let movementStartedString = `${hours}:${minutes}`
                    let movementWillFinish = `${adjustHours}:${adjustedMinutes}`
                    setOpeningInfo([`Movement started:`,movementStartedString])
                    setClosingTime([`Movement will complete at approx:`, movementWillFinish])

                }
                catch(e){
                    console.log("no movement started dt")
                    setOpeningInfo([`Movement started:`,'N/A'])
                    setClosingTime([`Movement will approx at:`, 'N/A'])
                }
                break

            case 0:
                if ( typeof props.stats.DtEstimatedOpening_UTC === "undefined"){
                    setOpeningInfo([``, ``])
                    setClosingTime([``, ``])
                    return
                }

                console.log(`${props.stats.DtEstimatedOpening_UTC}`)

                let formattedDate = `${props.stats.DtEstimatedOpening_UTC.date.replace(/ /g, 'T')}Z`
                let movementStarted = (new Date(`${formattedDate}`))

                let hours = checkDateVars(movementStarted.getHours());
                let minutes = checkDateVars(movementStarted.getMinutes())

                let bridgeWillCloseAt  = addTimeToDate(movementStarted, props.stats.EstimatedMinutesOpenFor)
                let endHours = checkDateVars(bridgeWillCloseAt.getHours())
                let endMinutes = checkDateVars(bridgeWillCloseAt.getMinutes())


                setOpeningInfo([`Estimated opening time:`, `${hours}:${minutes}`])
                setClosingTime([`Estimated closing time:`, `${endHours}:${endMinutes}`])
                break

            case 1:
                try{

                    let formattedDate = `${props.stats.dtOpened.date.replace(/ /g, 'T')}Z`
                    let movementStarted = (new Date(`${formattedDate}`))
                    let hours = checkDateVars(movementStarted.getHours());
                    let minutes = checkDateVars(movementStarted.getMinutes())

                    let bridgeWillCloseAt  = addTimeToDate(movementStarted, props.stats.EstimatedMinutesOpenFor)
                    let endHours = checkDateVars(bridgeWillCloseAt.getHours())
                    let endMinutes = checkDateVars(bridgeWillCloseAt.getMinutes())

                    let movementStartedString = `${hours}:${minutes}`
                    let movementStartedStrings = `${endHours}:${endMinutes}`

                    setOpeningInfo([`Bridge opened at approximately:`, movementStartedString])
                    setClosingTime([`Bridge will close at approximately:`, movementStartedStrings])
                    setShouldPreviousOpeningInfoBeHidden(true)
            }
            catch(e){
                console.log("no movement started dt")
                setOpeningInfo([`Bridge opened at approximately:`, ''])
                setClosingTime([`Bridge will close at approximately:`, ''])
                setShouldPreviousOpeningInfoBeHidden(true)
            }
            break

            default:
                break
        }
        // console.log(today.toString())
        // console.log(oneHourAhead.toString())
        // var diffMs = (oneHourAhead - today); // milliseconds between now then
        // console.log("MS")
        // console.log(diffMs)
        // var diffDays = Math.floor(diffMs / 86400000); // days
        // var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        // var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
        // console.log(diffMins)
        // console.log("Diff mins")
        // console.log(diffMins)

        // const adjustedDate =  datee.setHours(datee.getHours()+3)

        //console.error(props.stats.dtMovementStarted);
        // var datee =  new Date('2022-06-07 16:36:39.633000Z');
        // console.log(datee)
        // var date =  new Date('2022-06-07 16:36:39.633000', "yyyy-MM-dd HH:mm:ss.zzzzzz");
        // console.log(date)
        // console.log(date.toLocaleString())
        // //console.log(`${movementStarted.getHours()}:${movementStarted.getMinutes()}`)
        // console.log(new Date(`${props.stats.dtMovementStarted.date}Z`))
    },[props.stats])

    function checkDateVars(dateObjNumerical){
        let stringVal = ""

        if (dateObjNumerical < 10) {
            stringVal = `0${dateObjNumerical}`
        } else {
            stringVal = dateObjNumerical
        }
        return stringVal
        // let movementStartedString = `${hours}:${minutes}`
        // let movementWillFinish = `${hours}:${minutes + 10}`
    }

    function addTimeToDate(dateObj, minutes){
        return new Date(dateObj.getTime() + minutes*60000);

    }

    useEffect(() =>{
        console.log(props.previousOpening);
        setPreviousOpeningTime(`Last Opening (${props.previousOpening.EstimatedOpeningTime} - ${props.previousOpening.EstimatedClosingTime})`)
    }, [props.previousOpening])

    const showPopup = () =>{
        console.log("clicked")

    }

    const hidePopup = (e) =>{
        console.log(e);
    }

    return(
        <div>
            <div style={styles.textLeft}>
                    <table style={styles.spacingText}>
                        <tbody>
                            <tr>
                                <td>{openingInfo[0]}</td>
                                <td style={styles.paddingLeft}>{openingInfo[1]}</td>
                            </tr>
                            <tr>
                                <td>{closingTime[0]}</td>
                                <td style={styles.paddingLeft}>{closingTime[1]}</td>
                            </tr>

                        </tbody>
                    </table>
            </div>
            {props.stats.BridgeStatus === 1 &&
                <ProgressBar
                    min={0}
                    max={props.stats.EstimatedMinutesOpenFor}
                    value={props.stats.MinutesBridgeHasBeenOpenFor}
                    statusFormat={ (e,v) =>{
                        return ``
                    }}
                />
            }

            {props.stats.BridgeStatus === 1 &&
                <table style={{width:"96%", paddingLeft:"2%"}}>
                    <tbody>
                    <tr style={{textAlign:"left"}}>
                        <td>
                            {openingInfo[1]}
                        </td>
                        <td>
                        </td>
                        <td style={{textAlign:"right"}}>
                            {closingTime[1]}
                        </td>
                    </tr>
                    </tbody>
                </table>
            }




        </div>

    )
}

const styles = {
    titleRow: {
        fontWeight: "bold"
    },
    textLeft:{
        textAlign:"left",
        paddingLeft:15,
        paddingTop:20
    },
    spacingText:{
        paddingBottom:25
    },
    paddingLeft:{
        paddingLeft:15
    }

}
export default OpeningInformation;