import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DataGrid, { Column, MasterDetail, Scrolling } from 'devextreme-react/data-grid';
import { Button } from "devextreme-react";
import { TextBox } from "devextreme-react";
import {DateTime} from "luxon";


function ScheduledOpening(props) {
    const [time,setTime] = useState(Date().toString())
    const [minsTillOpen, setMinsTillOpen] = useState(0);
    const [colText, setColText] = useState("");


    useEffect( () =>{
        switch(props.stats.BridgeStatus){
            case -1:
               setColText(`No planned openings`)
                break
            case 0:
                setColText(`Next Planned Opening: ${props.stats.MinutesTillOpen} mins`)
                break
            case 1:
                setColText("Bridge is currently open")
                break
            default:
        }
    },[props.stats])

    useEffect(() => {
        currentTime()
    },[time])

    function currentTime() {

        let localDatee = DateTime.now().setZone("America/Vancouver")

        let hh = localDatee.hour;
        let mm = localDatee.minute;
        let ss = localDatee.second;

        hh = (hh < 10) ? "0" + hh : hh;
        mm = (mm < 10) ? "0" + mm : mm;
        ss = (ss < 10) ? "0" + ss : ss;

        let time = hh + ":" + mm + ":" + ss + " ";

        setTime(time);
        let t = setTimeout(function(){ currentTime() }, 1000);
    }

    return (
        <Container>
            <Row>
                {/*<Col  style={{backgroundColor:"#1c1cf0", color:"white"}}>*/}
                {/*    /!*<img src="./CP.png" style={{width:"40px", position:"absolute", left:"0"}}/>*!/*/}
                {/*    <h4 style={{marginTop:"0px"}}>Sicamous Bridge</h4>*/}
                {/*    </Col>*/}
                {/*<Col>*/}
                {/*    <div>hello</div>*/}
                {/*</Col>*/}
                {/*<Col>*/}
                {/*    wer*/}
                {/*</Col>*/}
            </Row>

            <Row style={styles.spacingBtwRows}>
                <Col sm={12}>Local time is: {time}</Col>
            </Row>

             <Row style={styles.spacingBtwRows}>
                 <Col></Col>
             </Row>
        </Container>
    )
}


const styles = {
    titleRow:{
        marginBottom: 15,
        fontWeight: "bold",
        marginTop:"0px"
    },
    spacingBtwRows:{
        marginBottom: 10
    },
    moveTextLeft:{
        textAlign: "left",
        marginLeft:10
    },
    detailsRow:{
        backgroundColor:"yellow"
    },
    scheduledOpeningRow:{
        paddingTop:120
    },
    requests:{
        paddingTop:60,
        textAlign: "left",
        marginLeft:10
    }

}

export default ScheduledOpening;