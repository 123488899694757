import React, { useState, useEffect } from 'react';
import { Button } from "devextreme-react";

function RequestSubmitted (props) {

    const returnHomePage = () =>{
        window.location.href = "/";
    }
    return (
        <table style={{height:"100vh", marginRight:"auto", marginLeft:"auto"}}>
            <tbody>
                <tr style={{width:"100vw", textAlign:"center"}}>
                    <td>
                        <p>Your request has been received.</p>
                        <p>Please monitor the main page for updated opening information. Have a safe day.</p>
                        <p>The bridge opens approximately within 5-30 minutes after a request</p>
                        <Button onClick={returnHomePage}
                                text={"Return to Main Page"}
                                type={"default"}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default RequestSubmitted;