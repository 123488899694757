import React, { useEffect, useState } from 'react';
import {Switch, Route, useLocation, BrowserRouter} from 'react-router-dom';
import { BrowserRouter as Router, Routes } from 'react-router-dom'
import Home from './com/home/Home'
import RequestParent from "./com/requests/RequestParent";

function Main(props) {

    // The root directory for React pages.
    // This takes the entire path (everything after the .com) and searches for the string at "props.rootDirectory".
    // The function then returns a string which is a substring of the entire path, minus everything to the right of "props.rootDirectory".



    return (
        <Routes>
            <Route exact path="/" element={<Home/>}> </Route>
            <Route exact path="/SubimtRequest" element={<RequestParent/>}> </Route>
            <Route path="*" element={<NotFound/>}>  </Route>
        </Routes>

    )
}

function NotFound(props) {
    let location = useLocation();
    console.log(location)
    console.log("location not found")
    return (
        <div className='App'>
            <div className='App-body'>
                <h3> The location of <p>{location.pathname}</p> is not found. <a href={props.rootDirectory}>Take me home.</a> </h3>
            </div>
        </div>
    )
}

export default Main;