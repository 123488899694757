import React, { useState, useEffect } from 'react';
import {Button, Toast} from "devextreme-react";
import { TextBox } from "devextreme-react";
import RequestSubmitted from "./RequestSubmitted"
import Map from 'devextreme-react/map';
import PublicFaqs from "../home/Faq/PublicFaqs";


function RequestParent(props) {

    const [didUserAllowLocationData, setUserLocationPermissions] = useState(false);
    const [submitRequest, setSubmitRequest] = useState(-1);

    const [toastConfig, setToastConfig] = useState({})
    const [markers, setMarkers] = useState([])
    const defaultCenter = "50.837411,-118.993291"
    const [isButtonActive, setIsButtonActive] = useState(false)
    const [isInfoPopupVisible, setIsInfoPopupVisible] = useState(false)
    const [locationText, setLocationText] = useState("Share My Location")
    const [popupText, setPopupText] = useState("");

    const [vesselName, setVesselName] = useState(undefined);
    const [cellNumber,setCellNumber] = useState(undefined)
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true)
    const [userLatitude, setUserLatitude] = useState(0.0);
    const [userLongitude, setUserLongitude] = useState(0.0);

    const [faqData, setFaqData] = useState({});
    const [width, setWidth] = useState("95%");
    const [imgLeft,setImgLeft] = useState("3%")

    useEffect (() =>{
        if (window.innerWidth > 1400){
            setWidth("80%")
            setImgLeft("10%")
        }

    },[])

    useEffect(() => {
        getF00Data()
        setMarkers([
            {
                location: [50.837411, -118.993291],
                tooltip: {
                    text: 'Sicamous Bridge'
                }
            }])

    }, [])


    const getF00Data = () => {
        fetch("https://webapisicbridge.azurewebsites.net/sicBridge.php?c=f00")
            .then(response => response.json())
            .then(data => {
                // console.log(data)
                setFaqData(data)
            })
    }

    const askUserLocation = (props) => {
        navigator.geolocation.getCurrentPosition(
            function (position) {
                // console.log("Latitude is :", position.coords.latitude);
                // console.log("Longitude is :", position.coords.longitude);
                setUserLatitude(position.coords.latitude);
                setUserLongitude(position.coords.longitude);
                setUserLocationPermissions(true)
                //50.851003, -119.011651 TIWN SIALD
                // 50.809084, -118.973080 sICAMOUS HOUSE BOATS
                var urlString = `https://webapisicbridge.azurewebsites.net/sicBridge.php?c=u01&VesselLat=${position.coords.latitude}&VesselLong=${position.coords.longitude}`
                //var urlString = `https://webapisicbridge.azurewebsites.net/sicBridge.php?c=u01&VesselLat=${50.809084}&VesselLong=${-118.973080}`
                console.log(urlString)
                fetch(urlString)
                    .then(data => data.json())
                    .then(dataFromDB =>{
                        console.log(dataFromDB[0].Result)
                        if(dataFromDB[0].Result === 101 || dataFromDB[0].Result === "101"){
                            userIsToFarAway()
                            return
                        }

                        setMarkers([...markers,
                            {
                                location: [position.coords.latitude, position.coords.longitude],
                                iconSrc: "https://sicbridgestorageacc.blob.core.windows.net/websiteimages/ferry-boat.png?sp=r&st=2023-05-08T16:03:49Z&se=2023-10-01T00:03:49Z&spr=https&sv=2022-11-02&sr=b&sig=b9jTbdvx36x%2BTnZdjoRJRRwiVrZ8o2oPufRUFW1ww%2FU%3D",
                                tooltip: {
                                    text: "User Location"
                                }
                            }
                        ])
                    })
            },
            function (error) {
                if (error.code === 1) {
                    // console.log("User wont allow for permissions");
                    setUserLocationPermissions(-1);
                    setPopupText("Vessel location is required. You are not sharing your location. To request an opening please call xxx-xxx-xxxx.")
                    setIsInfoPopupVisible(true)

                    setIsButtonActive(true)
                    setLocationText("Refresh page and share location")

                }
            }, {enableHighAccuracy: true, timeout: 5000}
        );
    }

    // useEffect(() => {
    //
    //     // console.log(didUserAllowLocationData);
    //
    // }, [didUserAllowLocationData])


    const updateVesselName = (props) => {
        console.log(`updated Vessel: ${props.value}`);
        setVesselName(props.value)
    }

    const updateCellNumber = (props) => {
        let res = /^[a-zA-Z]+$/.test(props.value)

        if(res === true){
            setIsValidPhoneNumber(false)
            setToastConfig({
                isVisible: true,
                type: "error",
                message: "Phone number can only contain numbers",
                displayTime: 1400
            })
            return
        }
        else{


            if(props.value.length === 10){
                let phoneWithDashes = `${props.value.substr(0,3)}-${props.value.substr(3,3)}-${props.value.substr(6,4)}`
                console.log(phoneWithDashes)
                setCellNumber(phoneWithDashes)
                setIsValidPhoneNumber(true)
                return
            }
            if(props.value.length === 12){
                setCellNumber(props.value)
                setIsValidPhoneNumber(true)
                return
            }

            setToastConfig({
                isVisible: true,
                type: "error",
                message: "Phone number needs to be 10 digits",
                displayTime: 1400
            })

            setIsValidPhoneNumber(false)
            setCellNumber(props.value)
        }

    }


    const requestOpening = (props) => {
        console.log("Request opening")
        console.log(`${isValidPhoneNumber} ${typeof vesselName} ${typeof cellNumber}`)


        if (( typeof cellNumber === "undefined" || (cellNumber.length !== 10 && cellNumber.length !== 12 )) || typeof vesselName === "undefined") {
            setToastConfig({
                isVisible: true,
                type: "error",
                message: "Vessel name and cell phone is required.",
                displayTime: 20000
            })
            console.log("error data criteria did not match")
            return
        }

        if(didUserAllowLocationData === false){
            setToastConfig({
                isVisible: true,
                type: "error",
                message: "Location must be shared to make an opening request from website. Please call this number 1888-222-4444 if you are unable or unwillling to share location.",
                displayTime: 1400
            })
            setIsInfoPopupVisible(true)
            return
        }

        var urlString = `https://webapisicbridge.azurewebsites.net/sicBridge.php?c=s06&VesselName=${vesselName}&PhoneNumber=${cellNumber}&VesselLat=${userLatitude}&VesselLong=${userLongitude}`
        console.log(urlString)

        fetch(urlString)
            .then(response => response.json())
            .then(data => {
                if (data[0].Result === 100) {
                    console.log("we got a 100 for request")
                    //Add fetch here
                    fetch("https://sendsicbridgesms.azurewebsites.net/api/SmsSender?")
                        .then(() =>{
                        })
                    setSubmitRequest(1)
                }
                if (data[0].Result === 101){
                    // The DB checked user location to see if they are close enough to request a bridge opening.
                    userIsToFarAway()
                }
            })

        console.log(`Requested opening. Vessel Name: ${vesselName} `)
    }


    const hideToast = () => {
        setToastConfig({
            visible: false,
        })
    }

    const hideWebsiteInfoPopup = () =>{
       setIsInfoPopupVisible(false)
    }

    const showInfoPopup = () =>{
        setPopupText("Enter info about website and openings")
        setIsInfoPopupVisible(true)
    }

    const userIsToFarAway = () => {

        setToastConfig({
            isVisible: true,
            type: "error",
            message: "You are to far away to make a request",
            displayTime: 2400
        })
    }


    const apiKey = {
        bing:"AoTGyBL_F8qItMIVbCj9Fvp5aeYCt3HSnVV5rE1QXYcA6Vi__5bFPVg7uNpxmJHr"
    }


    const styles = {
        oneHundredWith:{
            width:"100%"
        },
        textAlignCenter:{
            textAlign: "center",
            alignContent:"center",
            width:"50vw"
        }
    }

    const onFocusOut = (e) =>{
        document.body.style.zoom = "100%";
    }

    const goHome = () =>{
        window.location.href="/"
    }


    if (submitRequest === -1) {
        return (
            <div style={{width:width, marginRight:"auto", marginLeft:"auto"}}>
                <PublicFaqs faqData={faqData} divLocation={imgLeft}/>
                <div style={{marginTop:"5vh", width:width, marginRight:"auto", marginLeft:"auto", textAlign:"center"}}>Enter vessel name and phone number</div>
            <table style={styles.oneHundredWith}>
                <tbody>
                    <tr style={styles.oneHundredWith}>

                        <td style={styles.textAlignCenter}>
                            <TextBox onValueChanged={updateVesselName} stylingMode={"underlined"} onFocusOut={onFocusOut} placeholder={"Vessel Name"}/>
                        </td>
                        <td style={styles.textAlignCenter}>
                            <TextBox onValueChanged={updateCellNumber} stylingMode={"underlined"} isValid={isValidPhoneNumber} onFocusOut={onFocusOut} placeholder={"Phone Number"} value={cellNumber}/>
                        </td>

                    </tr>
                </tbody>
            </table>
            <table style={styles.oneHundredWith}>
                <tbody>
                    <tr style={styles.oneHundredWith}>
                        <td style={styles.textAlignCenter}>
                            <p>Press button to share your location</p>
                            <Button onClick={askUserLocation}
                                    stylingMode={"contained"}
                                    type={"default"}
                                    height={"4vh"}>
                                {locationText}
                            </Button>
                        </td>
                    </tr>

                    <tr style={styles.oneHundredWith}>
                        <td style={styles.textAlignCenter}>
                            <p>Press button to request an opening</p>
                            <Button
                                onClick={requestOpening} style={styles.buttonStyle}
                                stylingMode={"contained"}
                                type={"default"}
                                height={"4vh"}
                                disabled={isButtonActive}
                            >Request Opening</Button>

                        </td>
                    </tr>

                </tbody>
            </table>

                <div>
                    <Map
                        provider="bing"
                        defaultCenter={defaultCenter}
                        zoom={12.5}
                        width={"100%"}
                        height={"50vh"}
                        markers={markers}
                        apiKey={apiKey}
                        style={{zIndex:0}}
                        focusStateEnabled={true}
                    />

                </div>
                <div>
                    <Toast
                        message={toastConfig.message}
                        visible={toastConfig.isVisible}
                        displayTime={toastConfig.displayTime}
                        type={toastConfig.type}
                        onHiding={hideToast}
                        width="100%"
                        height={"15vh"}
                    />
                </div>

                {/*<Button*/}
                {/*    icon="info"*/}
                {/*    style={{position:"absolute", right:"3vw", top:"1vh"}}*/}
                {/*    className={"jap"}*/}
                {/*    onClick={showInfoPopup}*/}
                {/*/>*/}
                <Button
                    icon="back"
                    style={{position:"fixed", left:imgLeft, top:"2px"}}
                    height={"34px"}
                    onClick={goHome}

                />

                {/*<Popup*/}
                {/*    visible={isInfoPopupVisible}*/}

                {/*    dragEnabled={false}*/}
                {/*    closeOnOutsideClick={hideWebsiteInfoPopup}*/}
                {/*    showCloseButton={false}*/}
                {/*    showTitle={true}*/}
                {/*    title="Bridge Opening Help"*/}
                {/*    container=".dx-viewport"*/}
                {/*    width={"80vw"}*/}
                {/*    height={"70vh"}*/}
                {/*>*/}
                {/*    <Position*/}
                {/*        my="center"*/}
                {/*        at="center"*/}
                {/*        of={"window"}*/}

                {/*    />*/}
                {/*    <h4>*/}
                {/*        {popupText}*/}
                {/*    </h4>*/}
                {/*</Popup>*/}



            </div>
        )
    }
    else{
        return(
            <RequestSubmitted/>
        )
    }
}

export default RequestParent;