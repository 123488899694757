import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import DataGrid, { Column, MasterDetail, Scrolling } from 'devextreme-react/data-grid';
import { Button } from "devextreme-react";


function RequestStatus(props){

    const [areUsersAbleToMakeRequest,setAreUsersAbleToMakeRequest] = useState(false)
    const [mapCenter, setMapCenter] = useState([{latitude: 49.25708387012965, longitude: -122.7309580694427}]);
    const [brigeDivText, setBridgeDivText] = useState("");
    const [isDivVisible, setIsDivVisible] = useState("")
    const [estimatedOpeningText, setEstimatedOpeningText] = useState("");

    useEffect( () => {
        switch (props.stats.BridgeStatus){

            case -1:
                setBridgeDivText(`If you require the bridge to be open for passage, please request an opening.`);
                setEstimatedOpeningText("");
                break;

            case -2:
                setBridgeDivText(`If you require the bridge to be open for passage, please request an opening.`);
                setEstimatedOpeningText("");
                break;

            case 0:
                setBridgeDivText(`If you require the bridge to be open for passage, please request an opening.`);
                setEstimatedOpeningText(`${props.stats.EstimatedMinutesUntilOpen === undefined ? "No opening scheduled": `` }`);
                break;
                // ${props.stats.EstimatedMinutesUntilOpen === undefined ? `Estimated opening in ${props.stats.EstimatedMinutesUntilOpen}` : `No opening scheduled`

            case 1:
                setBridgeDivText("Bridge is open. Request an opening from below.")
                setEstimatedOpeningText("");
                break;

            default:
                break;
        }
    })
    function moveToRequestPage(e){
        console.log("button clicked")
        window.location.href = "/SubimtRequest"
    }



    return(
        <div>
             <div>
                 <p>{brigeDivText}</p>
                 <p>{estimatedOpeningText}</p>

             </div>
                <div>
                    <Button
                        onClick={moveToRequestPage}
                        stylingMode={"contained"}
                        type={"default"}
                        height={"4vh"}
                    > Request an Opening  </Button></div>
        </div>
    )
}

export default RequestStatus;