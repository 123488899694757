import logo from './logo.svg';
import './App.css';
import Home from "./com/home/Home";
import Main from './Main'
import React, { Component }  from 'react';

function App() {
  const rootDirectory = "/Default";

  return (
      <Main rootDirectory={rootDirectory} />
  );
}

export default App;
