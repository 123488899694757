import React, { useState, useEffect } from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { ProgressBar } from 'devextreme-react/progress-bar';
import 'devextreme/dist/css/dx.light.css';
import DataGrid, {Column} from 'devextreme-react/data-grid';

function PreviousOpeningsTable(props){


    const [previousOpenings, setPreviousOpenings] = useState()


    const renderCustomCell = (e) =>{
        if (e.value === null){
            return <div></div>
        }
        return <div>{e.value} mins</div>
    }

    useEffect(() =>{
        console.log("PREVIOUS OPENINGS")
        console.log(props.previousOpening)

        for(let i = 0; i < props.previousOpening.length; i++) {

            if(props.previousOpening[i].dtOpened !== null){

                let formattedDate = `${props.previousOpening[i].dtOpened.date.replace(/ /g, 'T')}Z`
                // console.log(formattedDate)
                // console.log(new Date(`${formattedDate}`))
                let movementStarted = (new Date(`${formattedDate}`))
                props.previousOpening[i].dtOpened.date = movementStarted

            }
            if(props.previousOpening[i].dtClosed !== null){
                if (props.previousOpening[i].dtMovementStarted === null){
                    continue
                }
                let formattedDate = `${props.previousOpening[i].dtMovementStarted.date.replace(/ /g, 'T')}Z`
                let bridgeWillCloseAt = (new Date(`${formattedDate}`))
                props.previousOpening[i].dtMovementStarted.date = bridgeWillCloseAt
            }
        }
        setPreviousOpenings(props.previousOpening)

    },[props.previousOpening])

    function checkDateVars(dateObjNumerical){
        let stringVal = ""

        if (dateObjNumerical < 10) {
            stringVal = `0${dateObjNumerical}`
        } else {
            stringVal = dateObjNumerical
        }
        return stringVal
        // let movementStartedString = `${hours}:${minutes}`
        // let movementWillFinish = `${hours}:${minutes + 10}`
    }


    return(

        <div style={{ marginBottom:"100px"}}>

            <DataGrid
                dataSource={previousOpenings}
                // width={"80%"}
                allowColumnReordering={false}
                // style={{marginRight:"auto", marginLeft:"auto"}}
                >

                <Column
                    dataField="dtOpened.date"
                    caption="Opening Time"
                    dataType="datetime"
                    alignment="center"
                    format={"MM/dd HH:mm"}
                    width={"37%"}
                    />

                <Column
                    dataField="dtMovementStarted.date"
                    caption="Closing Initiated"
                    dataType="datetime"
                    alignment="center"
                    format={"MM/dd HH:mm"}
                    width={"37%"}
                />

                <Column
                    dataField="MinutesUntilBridgeStartedMovement"
                    caption="Opened For"
                    dataType="string"
                    alignment="center"
                    cellRender={renderCustomCell}
                    widht={"26%"}
                />
            </DataGrid>

        </div>
    )
}

export default PreviousOpeningsTable;