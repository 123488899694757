import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import DataGrid, { Column, MasterDetail, Scrolling } from 'devextreme-react/data-grid';
import { Button } from "devextreme-react";
import { TextBox } from "devextreme-react";


function BridgeStatus(props){

    const [detailsText, setDetailsText] = useState("")
    const [detailsBackGroundColor, setDetailsBackGroundColor] = useState("")

    const [bridgeOpenURL, setBridgeOpenURL] = useState("https://sicbridgestorageacc.blob.core.windows.net/websiteimages/bridgeOpen.jpg?sv=2021-10-04&st=2023-04-10T22%3A59%3A44Z&se=2023-10-31T22%3A59%3A00Z&sr=b&sp=r&sig=fwdeiQjORprfANGBHFfX%2B7RctSc4S7YFb41tgaUkLNw%3D")
    const [bridgeClosedURL, setBridgeClosedURL] = useState("https://sicbridgestorageacc.blob.core.windows.net/websiteimages/bridgeClosed.jpg?sv=2021-10-04&st=2023-04-10T22%3A58%3A53Z&se=2023-10-31T22%3A58%3A00Z&sr=b&sp=r&sig=9h3wKsNi8L7gf6OTmsZDDugVnRPx7xvP8L6kKG%2Fdy4Y%3D")

    useEffect(() => {

        console.log(props.stats)

        switch(props.stats.BridgeStatus){
            case -2:
                setDetailsText(`DO NOT APPROACH BRIDGE`)
                setDetailsBackGroundColor("Red")
                break

            case -1:
                setDetailsText(`DO NOT APPROACH BRIDGE`)
                setDetailsBackGroundColor("Red")
                break

            case 0:
                setDetailsText(`DO NOT APPROACH BRIDGE`)
                setDetailsBackGroundColor("Red")
                break

            case 1:
                setDetailsText(``)
                break

            default:
                setDetailsText("Something went wrong getting data")
                break
        }
    },[props.stats])

    return(
        <div>
            <div style={{backgroundColor:detailsBackGroundColor, marginBottom:"30px", marginTop:"20px", fontWeight:"bold"}}>{detailsText}</div>
            <div>
                 <img src={props.stats.BridgeStatus === 1? bridgeOpenURL : bridgeClosedURL} style={{width:"80%", maxWidth:700}}/>
            </div>
        </div>
    )
}

const styles = {
    titleRow: {
        fontWeight: "bold"
    }
}
export default BridgeStatus;