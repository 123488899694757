import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import BridgeStatus from "./BridgeStatus/BridgeStatus";
import OpeningInformation from "./ScheduledOpening/OpeningInformation";
import RequestStatus from "./RequestStatus/RequestStatus";
import GeneralInfo from "./Header/Header"
import PreviousOpeningsTable from "./PreviousOpeningsTable/PreviousOpeningsTable";
import {Popup, Position} from 'devextreme-react/popup';

import PublicFaqs from "./Faq/PublicFaqs";

function Home(props){

    const [bridgeStatusObj, setBridgeStatusObj] = useState({});
    const [faqObject, setFaqObject]  = useState({});
    const [detailsDivBackGroundColor, setDetailsDivBackGroundColor] = useState("");
    const [previousOpeningInfo, setPreviousOpeningInfo] = useState({});

    const [bridgeOrientation, setBridgeOrientation] = useState("");
    const [bottomOfPageHeader, setBottomOfPageHeader] = useState("");
    const [refreshData,setRefreshData] = useState(false);

    const [isInfoPopupVisible, setIsInfoPopupVisible] = useState(false);
    const [popupText, setPopupText] = useState("");
    const [bridgeTitle, setBridgeTitle] = useState("")
    const [width, setWidth] = useState("95%");
    const [imgLeft,setImgLeft] = useState("3%")


    useEffect(()=>{

        if (window.innerWidth > 1400){
            setWidth("80%")
            setImgLeft("10%")
        }

        emptyCache()
        getS04Data()
        getS05Data()
        getF00Data()

        const interval=setInterval(()=>{

            getS04Data()
            getS05Data()
            getF00Data()

        },60000)


        return()=>clearInterval(interval)
    },[])




    function emptyCache(){
        if('caches' in window){
            caches.keys().then((names) => {
                console.log(names)
                // Delete all the cache files
                names.forEach(name => {
                    caches.delete(name);
                })
            });
        }
    }


    const getF00Data = () => {
        fetch("https://webapisicbridge.azurewebsites.net/sicBridge.php?c=f00")
            .then(response => response.json())
            .then(data => {
                // console.log(data)
                setFaqObject(data)
            })
    }
    const getS05Data = () =>{
        fetch("https://webapisicbridge.azurewebsites.net/sicBridge.php?c=s05")
            .then(response => response.json())
            .then(data => {
                try {
                    // console.log("Previous openings info:")
                    // console.log(data[0])
                    setPreviousOpeningInfo(data)
                }
                catch (e){
                    console.error("Error setting previous data")
                    setPreviousOpeningInfo({"Error":-999})
                }
            })
    }



    const getS04Data = () => {
        fetch("https://webapisicbridge.azurewebsites.net/sicBridge.php?c=s04")
            .then(response => response.json())
            .then(data => {
                // console.log(data[0])

                try{
                    // console.log(`is bridge open: ${data[0].BridgeStatus}`)

                    let bridgeStatus = parseInt(data[0].BridgeStatus)


                    switch(bridgeStatus){
                        case -3:
                            // bridge unattended / out of order
                            setPopupText("Bridge is out of order")
                            setBridgeTitle("Bridge is out of order")
                            setIsInfoPopupVisible(true)
                            return
                        case -2:
                            setBridgeOrientation("Closed")
                            setBottomOfPageHeader("Previous Opening")
                            setIsInfoPopupVisible(false)
                            break

                        case -1:
                            setBridgeOrientation("Opening")
                            setBottomOfPageHeader("Previous Opening")
                            setIsInfoPopupVisible(false)
                            break

                        case 0:
                            setBridgeOrientation("Closed")
                            setBottomOfPageHeader("Insert long text")
                            setIsInfoPopupVisible(false)
                            break
                        case 1:
                            setBridgeOrientation("Opened")
                            setBottomOfPageHeader("Previous Opening")
                            setIsInfoPopupVisible(false)
                            break

                        default:
                            setDetailsDivBackGroundColor(null);

                    }
                    console.log("Bridge data set");
                    setBridgeStatusObj(data[0])
                }
                catch (e){
                    console.log("Not able to properly get bridge status data")
                    //setMinsTillOpen(-999)
                }
            })
    }

    const hideWebsiteInfoPopup = () =>{
        setIsInfoPopupVisible(false)
    }




    return (
        <div className="App" style={{width:width, marginRight:"auto", marginLeft:"auto", display:"block"}}>
            <div className="App-body">

                <Container fluid>
                    <Row lg={12}>
                        <PublicFaqs faqData={faqObject} divLocation={imgLeft}/>
                        <img src={"./CP.png"} style={{height:"60px", position:"fixed", left:imgLeft, top:"-1%"}}/>
                    </Row>
                   <Row>
                       <br/>
                       <br/>
                       <GeneralInfo stats={bridgeStatusObj}/>
                   </Row>
                    <Row sm={12}>
                        <div style={styles.detailsRowHeader}>Bridge Status: {bridgeOrientation}</div>
                    </Row>
                    <Row>
                        <BridgeStatus stats={bridgeStatusObj}/>
                    </Row>
                    <Row style={styles.scheduledOpeningRow}>
                        <OpeningInformation stats={bridgeStatusObj} previousOpening={previousOpeningInfo}/>
                    </Row>

                    <Row sm={12}>
                        <RequestStatus stats={bridgeStatusObj}/>
                    </Row>
                    <Row style={{textAlign:"left", marginLeft:10, marginTop:50}}>
                        Previous Openings
                    </Row>
                    <Row sm={12}>
                        <PreviousOpeningsTable previousOpening={previousOpeningInfo}/>
                    </Row>
                </Container>
            </div>

            <Popup
                visible={isInfoPopupVisible}
                dragEnabled={false}
                showCloseButton={false}
                showTitle={true}
                title={bridgeTitle}
                container=".dx-viewport"
                width={"80vw"}
                height={"70vh"}
            >
                <Position
                    my="center"
                    at="center"
                    of={"window"}

                />
                <h4>
                    {popupText}
                </h4>
            </Popup>
        </div>
    )
}


const styles = {
    titleRow:{
        paddingTop: 20,
        marginBottom: 15,
        fontWeight: "bold",
    },
    spacingBtwRows:{
        marginBottom: 10
    },
    detailsRowHeader:{
        textAlign: "left",
        marginLeft:10,
        marginTop:20,
        fontWeight:"bold"
    },
    scheduledOpeningRow:{
        paddingTop:40
    },
    requests:{
        paddingTop:60,
        textAlign: "left",
        marginLeft:10,
        marginBottom:20
    }

}
export default Home;